import {
    CHANGE_SEARCH_FIELD,
    CLICK_BACK_TO_TOP,
    LEFTSIDEBAR_CLOSE,
    RIGHTSIDEBAR_CLOSE,
    CLICK_DROPDOWN,
    CLICK_MENU,
    CLICK_MESSAGES,
    CLICK_SETTINGS,
    CLICK_OUT,
    CHANGEROUTE,
    SEGUNDA_CLICK,
    TERCA_CLICK,
    QUARTA_CLICK,
    QUINTA_CLICK,
    SEXTA_CLICK,
    SABADO_CLICK,
    DOMINGO_CLICK,
    ACCORDION_SEGUNDA_CLICK,
    ACCORDION_TERCA_CLICK,
    ACCORDION_QUARTA_CLICK,
    ACCORDION_QUINTA_CLICK,
    ACCORDION_SEXTA_CLICK,
    ACCORDION_SABADO_CLICK,
    ACCORDION_DOMINGO_CLICK,
    CYCLESLIDESHOW,
    TOGGLEVISIBILITY,
    ADDTOGGLEVISIBILITY,
    REMOVETOGGLEVISIBILITY,
    HIDEALLEXCEPT,
    REQUEST_PRESENTATION_PENDING,
    REQUEST_PRESENTATION_SUCCESS,
    REQUEST_PRESENTATION_FAILED,
    UPDATE_PRESENTATION_PENDING,
    UPDATE_PRESENTATION_SUCCESS,
    UPDATE_PRESENTATION_FAILED,
    REQUEST_INTRO_PENDING,
    REQUEST_INTRO_SUCCESS,
    REQUEST_INTRO_FAILED,
    UPDATE_INTRO_PENDING,
    UPDATE_INTRO_SUCCESS,
    UPDATE_INTRO_FAILED,
    REQUEST_NEXTEVENT_PENDING,
    REQUEST_NEXTEVENT_SUCCESS,
    REQUEST_NEXTEVENT_FAILED,
    UPDATE_NEXTEVENT_PENDING,
    UPDATE_NEXTEVENT_SUCCESS,
    UPDATE_NEXTEVENT_FAILED,
    REQUEST_QUOTES_PENDING,
    REQUEST_QUOTES_SUCCESS,
    REQUEST_QUOTES_FAILED,
    UPDATE_QUOTES_PENDING,
    UPDATE_QUOTES_SUCCESS,
    UPDATE_QUOTES_FAILED,
    REQUEST_PORTFOLIO_PENDING,
    REQUEST_PORTFOLIO_SUCCESS,
    REQUEST_PORTFOLIO_FAILED,
    UPDATE_PORTFOLIO_PENDING,
    UPDATE_PORTFOLIO_SUCCESS,
    UPDATE_PORTFOLIO_FAILED,
    REQUEST_PORTFOLIO_TITLE_PENDING,
    REQUEST_PORTFOLIO_TITLE_SUCCESS,
    REQUEST_PORTFOLIO_TITLE_FAILED,
    UPDATE_PORTFOLIO_TITLE_PENDING,
    UPDATE_PORTFOLIO_TITLE_SUCCESS,
    UPDATE_PORTFOLIO_TITLE_FAILED,
    REQUEST_HORARIO_PENDING,
    REQUEST_HORARIO_SUCCESS,
    REQUEST_HORARIO_FAILED,
    UPDATE_HORARIO_PENDING,
    UPDATE_HORARIO_SUCCESS,
    UPDATE_HORARIO_FAILED,
    REQUEST_NOTA_FERIAS_PENDING,
    REQUEST_NOTA_FERIAS_SUCCESS,
    REQUEST_NOTA_FERIAS_FAILED,
    REQUEST_CONTACTS_PENDING,
    REQUEST_CONTACTS_SUCCESS,
    REQUEST_CONTACTS_FAILED,
    REQUEST_METODOS_PENDING,
    REQUEST_METODOS_SUCCESS,
    REQUEST_METODOS_FAILED,
    REQUEST_REDES_PENDING,
    REQUEST_REDES_SUCCESS,
    REQUEST_REDES_FAILED,
    UPDATE_DETAILS_PENDING,
    UPDATE_DETAILS_SUCCESS,
    UPDATE_DETAILS_FAILED,
    REQUEST_EVENTS_PENDING,
    REQUEST_EVENTS_SUCCESS,
    REQUEST_EVENTS_FAILED,
    UPDATE_EVENTS_PENDING,
    UPDATE_EVENTS_SUCCESS,
    UPDATE_EVENTS_FAILED,
    REQUEST_EVENTDETAILS_PENDING,
    REQUEST_EVENTDETAILS_SUCCESS,
    REQUEST_EVENTDETAILS_FAILED,
    REQUEST_EVENTPHOTOS_PENDING,
    REQUEST_EVENTPHOTOS_SUCCESS,
    REQUEST_EVENTPHOTOS_FAILED,
    UPDATE_EVENTPHOTOS_PENDING,
    UPDATE_EVENTPHOTOS_SUCCESS,
    UPDATE_EVENTPHOTOS_FAILED,
    DELETE_PHOTO_PENDING,
    DELETE_PHOTO_SUCCESS,
    DELETE_PHOTO_FAILED,
    UPDATE_USERDATA_PENDING,
    UPDATE_USERDATA_SUCCESS,
    UPDATE_USERDATA_FAILED,
    UPDATE_LIKES_PENDING,
    UPDATE_LIKES_SUCCESS,
    UPDATE_LIKES_FAILED,
    UPDATE_CLIENTLIST_PENDING,
    UPDATE_CLIENTLIST_SUCCESS,
    UPDATE_CLIENTLIST_FAILED,
    DELETE_CLIENT_PENDING,
    DELETE_CLIENT_SUCCESS,
    DELETE_CLIENT_FAILED
} from './constants'

/*
JSON.parse() When receiving data from a web server, the data is always a string.
Parse the data with JSON.parse(), and the data becomes a JavaScript object.

JSON.stringify() When sending data to a web server, the data has to be a string.
Convert a JavaScript object into a string with JSON.stringify().

REGEX
_FAILED:\s*\r*return Object.assign\(\{\}, state, \{.*\}\);
*/


const initialSearchState = {
    searchField: ''
}

export const searchEntity = (state = initialSearchState, action = {}) => {
    switch (action.type) {
        case CHANGE_SEARCH_FIELD:
            return Object.assign({}, state, { searchField: action.payload }); //Same as : return { ...state, {searchField: action.payload}}
        default:
            return state;
    }
}


const initialRouteState = {
    route: '',
    id: 0
}

export const ChangeRoute = (state = initialRouteState, action = {}) => {
    switch (action.type) {
        case CHANGEROUTE:
            return Object.assign({}, state, { route: action.payload.route, id: action.payload.id });
        default:
            return state;
    }
}

//######################

const initialSideBarsState = {
    is_RightSideBar_Hidden: true,
    is_LeftSideBar_Hidden: true
}

export const SideBars = (state = initialSideBarsState, action = {}) => {
    switch (action.type) {
        case CLICK_BACK_TO_TOP:
            return Object.assign({}, state, { is_RightSideBar_Hidden: !state.is_RightSideBar_Hidden, is_LeftSideBar_Hidden: !state.is_LeftSideBar_Hidden });
        case LEFTSIDEBAR_CLOSE:
            return Object.assign({}, state, { is_LeftSideBar_Hidden: true });
        case RIGHTSIDEBAR_CLOSE:
            return Object.assign({}, state, { is_RightSideBar_Hidden: true });
        default:
            return state;
    }
}

const initialNavBarState = {
    is_DropDownList_Hidden: true,
    is_MenuList_Hidden: true,
    is_MessagesList_Hidden: true,
    is_SettingsList_Hidden: true
}

export const NavBar = (state = initialNavBarState, action = {}) => {
    switch (action.type) {
        case CLICK_DROPDOWN:
            return Object.assign({}, state, { is_DropDownList_Hidden: !state.is_DropDownList_Hidden });
        case CLICK_MENU:
            return Object.assign({}, state, { is_MenuList_Hidden: !state.is_MenuList_Hidden });
        case CLICK_MESSAGES:
            return Object.assign({}, state, { is_MessagesList_Hidden: !state.is_MessagesList_Hidden });
        case CLICK_SETTINGS:
            return Object.assign({}, state, { is_SettingsList_Hidden: !state.is_SettingsList_Hidden });
        case CLICK_OUT:
            return Object.assign({}, state, {
                is_DropDownList_Hidden: true,
                is_MenuList_Hidden: true,
                is_MessagesList_Hidden: true,
                is_SettingsList_Hidden: true
            });
        default:
            return state;
    }
}

const initialToggleWeekDay = {
    is_Accordion_Segunda_Hidden: true,
    is_Accordion_Terca_Hidden: true,
    is_Accordion_Quarta_Hidden: true,
    is_Accordion_Quinta_Hidden: true,
    is_Accordion_Sexta_Hidden: true,
    is_Accordion_Sabado_Hidden: true,
    is_Accordion_Domingo_Hidden: true,
    is_Panel_Segunda_Hidden: true,
    is_Panel_Terca_Hidden: true,
    is_Panel_Quarta_Hidden: true,
    is_Panel_Quinta_Hidden: true,
    is_Panel_Sexta_Hidden: true,
    is_Panel_Sabado_Hidden: true,
    is_Panel_Domingo_Hidden: true
}

export const ToggleWeekDay = (state = initialToggleWeekDay, action = {}) => {
    switch (action.type) {
        case SEGUNDA_CLICK:
            return Object.assign({}, state, { is_Accordion_Segunda_Hidden: !state.is_Accordion_Segunda_Hidden });
        case TERCA_CLICK:
            return Object.assign({}, state, { is_Accordion_Terca_Hidden: !state.is_Accordion_Terca_Hidden });
        case QUARTA_CLICK:
            return Object.assign({}, state, { is_Accordion_Quarta_Hidden: !state.is_Accordion_Quarta_Hidden });
        case QUINTA_CLICK:
            return Object.assign({}, state, { is_Accordion_Quinta_Hidden: !state.is_Accordion_Quinta_Hidden });
        case SEXTA_CLICK:
            return Object.assign({}, state, { is_Accordion_Sexta_Hidden: !state.is_Accordion_Sexta_Hidden });
        case SABADO_CLICK:
            return Object.assign({}, state, { is_Accordion_Sabado_Hidden: !state.is_Accordion_Sabado_Hidden });
        case DOMINGO_CLICK:
            return Object.assign({}, state, { is_Accordion_Domingo_Hidden: !state.is_Accordion_Domingo_Hidden });
        case ACCORDION_SEGUNDA_CLICK:
            return Object.assign({}, state, { is_Panel_Segunda_Hidden: !state.is_Panel_Segunda_Hidden });
        case ACCORDION_TERCA_CLICK:
            return Object.assign({}, state, { is_Panel_Terca_Hidden: !state.is_Panel_Terca_Hidden });
        case ACCORDION_QUARTA_CLICK:
            return Object.assign({}, state, { is_Panel_Quarta_Hidden: !state.is_Panel_Quarta_Hidden });
        case ACCORDION_QUINTA_CLICK:
            return Object.assign({}, state, { is_Panel_Quinta_Hidden: !state.is_Panel_Quinta_Hidden });
        case ACCORDION_SEXTA_CLICK:
            return Object.assign({}, state, { is_Panel_Sexta_Hidden: !state.is_Panel_Sexta_Hidden });
        case ACCORDION_SABADO_CLICK:
            return Object.assign({}, state, { is_Panel_Sabado_Hidden: !state.is_Panel_Sabado_Hidden });
        case ACCORDION_DOMINGO_CLICK:
            return Object.assign({}, state, { is_Panel_Domingo_Hidden: !state.is_Panel_Domingo_Hidden });

        default:
            return state;
    }
}

const initialCycleSlideshow = {
    img_list: [],
    quote_list: ["Patricia Gonçalves", "Clara Gonçalves", "Lurdes Gonçalves", "Domingos Gonçalves", "Bem Vindos", "à", "Clarícia"],
    img_index: 0,
    quote_index: 0
}

export const CycleSlideshow = (state = initialCycleSlideshow, action = {}) => {
    switch (action.type) {
        case CYCLESLIDESHOW:
            {
                if (state.img_index < 6)
                    return Object.assign({}, state, { img_index: state.img_index + 1, quote_index: state.quote_index + 1 });
                else
                    return Object.assign({}, state, { img_index: 0, quote_index: 0 });
            }
        default:
            return state;
    }
}

const initialToggleVisibility = {
    is_Hidden: [], /* Maybe change into an array of objects with name of entity and its visibility state. */
}

export const ToggleVisibility = (state = initialToggleVisibility, action = {}) => {
    switch (action.type) {
        case TOGGLEVISIBILITY:
            return Object.assign({}, state, {
                is_Hidden: state.is_Hidden.map((is_Hidden_Element) => {
                    if (is_Hidden_Element.name === action.payload) {
                        is_Hidden_Element.value = (!is_Hidden_Element.value);
                        return is_Hidden_Element;
                    }
                    else if(((action.payload === "portfolio_button") || (action.payload === "horario_button") || (action.payload === "listaclientes_button") || (action.payload === "contatos_button")) && !(is_Hidden_Element.name === action.payload)){
                        is_Hidden_Element.value = true;
                        return is_Hidden_Element;
                    }
                    else{
                        return is_Hidden_Element;
                    }
                })
            })
        case ADDTOGGLEVISIBILITY:
            return Object.assign({}, state, { is_Hidden: [...state.is_Hidden, { name: action.payload, value: true }] })
        case REMOVETOGGLEVISIBILITY:
            let newArray = state.is_Hidden.filter(element => element.name !== action.payload)/* state.is_Hidden.slice();
            newArray.splice(-1,1); */
            return Object.assign({}, state, { is_Hidden: newArray });
        case HIDEALLEXCEPT:
            return Object.assign({}, state, {
                is_Hidden: state.is_Hidden.map((is_Hidden_Element) => {
                    if (is_Hidden_Element.name === action.payload) {
                        is_Hidden_Element.value = (!is_Hidden_Element.value);
                        return is_Hidden_Element;
                    }
                    else {
                        is_Hidden_Element.value = true; //if hidding everything else is needed!
                        return is_Hidden_Element;
                    }
                })
            })
        default:
            return state;
    }
}

const initialPresentationState = {
    presentation_data_isPending: true,
    presentation_data: [],
    presentation_data_isSuccess: false,
    response_presentation_data: [],
    message_presentation_data: "",
    error_presentation_data: ""
}

export const Presentation = (state = initialPresentationState, action = {}) => {
    switch (action.type) {
        case REQUEST_PRESENTATION_PENDING:
            return Object.assign({}, state, { presentation_data_isPending: true });
        case REQUEST_PRESENTATION_SUCCESS:
            return Object.assign({}, state, {
                presentation_data_isPending: false,
                presentation_data: action.payload,
                presentation_data_isSuccess: action.payload.success,
                response_presentation_data: action.payload.response,
                message_presentation_data: action.payload.message
            });
        case REQUEST_PRESENTATION_FAILED:
            return Object.assign({}, state, {
                presentation_data_isPending: false,
                presentation_data_isSuccess: action.payload.success,
                error_presentation_data: action.payload.message
            });
        case UPDATE_PRESENTATION_PENDING:
            return Object.assign({}, state, { presentation_data_isPending: true });
        case UPDATE_PRESENTATION_SUCCESS:
            return Object.assign({}, state, {
                presentation_data_isPending: false,
                presentation_data: action.payload,
                presentation_data_isSuccess: action.payload.success,
                response_presentation_data: action.payload.response,
                message_presentation_data: action.payload.message
            });
        case UPDATE_PRESENTATION_FAILED:
            return Object.assign({}, state, {
                presentation_data_isPending: false,
                presentation_data_isSuccess: action.payload.success,
                error_presentation_data: action.payload.message
            });
        default:
            return state;
    }
}

const initialIntroState = {
    intro_data_isPending: true,
    intro_data: [],
    intro_data_isSuccess: false,
    response_intro_data: [],
    message_intro_data: "",
    error_intro_data: ""
}

export const Intro = (state = initialIntroState, action = {}) => {
    switch (action.type) {
        case REQUEST_INTRO_PENDING:
            return Object.assign({}, state, { intro_data_isPending: true });
        case REQUEST_INTRO_SUCCESS:
            return Object.assign({}, state, {
                intro_data_isPending: false,
                intro_data: action.payload,
                intro_data_isSuccess: action.payload.success,
                response_intro_data: action.payload.response,
                message_intro_data: action.payload.message
            });
        case REQUEST_INTRO_FAILED:
            return Object.assign({}, state, {
                intro_data_isPending: false,
                intro_data_isSuccess: action.payload.success,
                error_intro_data: action.payload.message
            });
        case UPDATE_INTRO_PENDING:
            return Object.assign({}, state, { intro_data_isPending: true });
        case UPDATE_INTRO_SUCCESS:
            return Object.assign({}, state, {
                intro_data_isPending: false,
                intro_data: action.payload,
                intro_data_isSuccess: action.payload.success,
                response_intro_data: action.payload.response,
                message_intro_data: action.payload.message
            });
        case UPDATE_INTRO_FAILED:
            return Object.assign({}, state, {
                intro_data_isPending: false,
                intro_data_isSuccess: action.payload.success,
                error_intro_data: action.payload.message
            });
        default:
            return state;
    }
}

const initialNextEventState = {
    nextevent_data_isPending: true,
    nextevent_data: [],
    nextevent_data_isSuccess: false,
    response_nextevent_data: [],
    message_nextevent_data: "",
    error_nextevent_data: ""
}

export const NextEvent = (state = initialNextEventState, action = {}) => {
    switch (action.type) {
        case REQUEST_NEXTEVENT_PENDING:
            return Object.assign({}, state, { nextevent_data_isPending: true });
        case REQUEST_NEXTEVENT_SUCCESS:
            return Object.assign({}, state, {
                nextevent_data_isPending: false,
                nextevent_data: action.payload,
                nextevent_data_isSuccess: action.payload.success,
                response_nextevent_data: action.payload.response,
                message_nextevent_data: action.payload.message
            });
        case REQUEST_NEXTEVENT_FAILED:
            return Object.assign({}, state, {
                nextevent_data_isPending: false,
                nextevent_data_isSuccess: action.payload.success,
                error_nextevent_data: action.payload.message
            });
        case UPDATE_NEXTEVENT_PENDING:
            return Object.assign({}, state, { nextevent_data_isPending: true });
        case UPDATE_NEXTEVENT_SUCCESS:
            return Object.assign({}, state, {
                nextevent_data_isPending: false,
                nextevent_data: action.payload,
                nextevent_data_isSuccess: action.payload.success,
                response_nextevent_data: action.payload.response,
                message_nextevent_data: action.payload.message
            });
        case UPDATE_NEXTEVENT_FAILED:
            return Object.assign({}, state, {
                nextevent_data_isPending: false,
                nextevent_data_isSuccess: action.payload.success,
                error_nextevent_data: action.payload.message
            });
        default:
            return state;
    }
}

const initialQuotesState = {
    quotes_data_isPending: true,
    quotes_data: [],
    quotes_data_isSuccess: false,
    response_quotes_data: [],
    message_quotes_data: "",
    error_quotes_data: ""
}

export const Quotes = (state = initialQuotesState, action = {}) => {
    switch (action.type) {
        case REQUEST_QUOTES_PENDING:
            return Object.assign({}, state, { quotes_data_isPending: true });
        case REQUEST_QUOTES_SUCCESS:
            return Object.assign({}, state, {
                quotes_data_isPending: false,
                quotes_data: action.payload,
                quotes_data_isSuccess: action.payload.success,
                response_quotes_data: action.payload.response,
                message_quotes_data: action.payload.message
            });
        case REQUEST_QUOTES_FAILED:
            return Object.assign({}, state, {
                quotes_data_isPending: false,
                quotes_data_isSuccess: action.payload.success,
                error_quotes_data: action.payload.message
            });
        case UPDATE_QUOTES_PENDING:
            return Object.assign({}, state, { quotes_data_isPending: true });
        case UPDATE_QUOTES_SUCCESS:
            return Object.assign({}, state, {
                quotes_data_isPending: false,
                quotes_data: action.payload,
                quotes_data_isSuccess: action.payload.success,
                response_quotes_data: action.payload.response,
                message_quotes_data: action.payload.message
            });
        case UPDATE_QUOTES_FAILED:
            return Object.assign({}, state, {
                quotes_data_isPending: false,
                quotes_data_isSuccess: action.payload.success,
                error_quotes_data: action.payload.message
            });
        default:
            return state;
    }
}

const initialPortfolioState = {
    portfolio_data_isPending: true,
    portfolio_data: [],
    portfolio_data_isSuccess: false,
    response_portfolio_data: [],
    message_portfolio_data: "",
    error_portfolio_data: ""
}

export const Portfolio = (state = initialPortfolioState, action = {}) => {
    switch (action.type) {
        case REQUEST_PORTFOLIO_PENDING:
            return Object.assign({}, state, { portfolio_data_isPending: true });
        case REQUEST_PORTFOLIO_SUCCESS:
            return Object.assign({}, state, {
                portfolio_data_isPending: false,
                portfolio_data: action.payload,
                portfolio_data_isSuccess: action.payload.success,
                response_portfolio_data: action.payload.response,
                message_portfolio_data: action.payload.message
            });
        case REQUEST_PORTFOLIO_FAILED:
            return Object.assign({}, state, {
                portfolio_data_isPending: false,
                portfolio_data_isSuccess: action.payload.success,
                error_portfolio_data: action.payload.message
            });
        case UPDATE_PORTFOLIO_PENDING:
            return Object.assign({}, state, { portfolio_data_isPending: true });
        case UPDATE_PORTFOLIO_SUCCESS:
            return Object.assign({}, state, {
                portfolio_data_isPending: false,
                portfolio_data: action.payload,
                portfolio_data_isSuccess: action.payload.success,
                response_portfolio_data: action.payload.response,
                message_portfolio_data: action.payload.message
            });
        case UPDATE_PORTFOLIO_FAILED:
            return Object.assign({}, state, {
                portfolio_data_isPending: false,
                portfolio_data_isSuccess: action.payload.success,
                error_portfolio_data: action.payload.message
            });
        default:
            return state;
    }
}

const initialPortfolioTitleState = {
    portfolio_title_data_isPending: true,
    portfolio_title_data: [],
    portfolio_title_data_isSuccess: false,
    response_portfolio_title_data: [],
    message_portfolio_title_data: "",
    error_portfolio_title_data: ""
}

export const Portfolio_Title = (state = initialPortfolioTitleState, action = {}) => {
    switch (action.type) {
        case REQUEST_PORTFOLIO_TITLE_PENDING:
            return Object.assign({}, state, { portfolio_title_data_isPending: true });
        case REQUEST_PORTFOLIO_TITLE_SUCCESS:
            return Object.assign({}, state, {
                portfolio_title_data_isPending: false,
                portfolio_title_data: action.payload,
                portfolio_title_data_isSuccess: action.payload.success,
                response_portfolio_title_data: action.payload.response,
                message_portfolio_title_data: action.payload.message
            });
        case REQUEST_PORTFOLIO_TITLE_FAILED:
            return Object.assign({}, state, {
                portfolio_title_data_isPending: false,
                portfolio_title_data_isSuccess: action.payload.success,
                error_portfolio_title_data: action.payload.message
            });
        case UPDATE_PORTFOLIO_TITLE_PENDING:
            return Object.assign({}, state, { portfolio_title_data_isPending: true });
        case UPDATE_PORTFOLIO_TITLE_SUCCESS:
            return Object.assign({}, state, {
                portfolio_title_data_isPending: false,
                portfolio_title_data: action.payload,
                portfolio_title_data_isSuccess: action.payload.success,
                response_portfolio_title_data: action.payload.response,
                message_portfolio_title_data: action.payload.message
            });
        case UPDATE_PORTFOLIO_TITLE_FAILED:
            return Object.assign({}, state, {
                portfolio_title_data_isPending: false,
                portfolio_title_data_isSuccess: action.payload.success,
                error_portfolio_title_data: action.payload.message
            });
        default:
            return state;
    }
}

const initialHorarioState = {
    horario_data_isPending: true,
    horario_data: [],
    horario_data_isSuccess: false,
    response_horario_data: [],
    message_horario_data: "",
    error_horario_data: ""
}

export const Horario = (state = initialHorarioState, action = {}) => {
    switch (action.type) {
        case REQUEST_HORARIO_PENDING:
            return Object.assign({}, state, { horario_data_isPending: true });
        case REQUEST_HORARIO_SUCCESS:
            return Object.assign({}, state, {
                horario_data_isPending: false,
                horario_data: action.payload,
                horario_data_isSuccess: action.payload.success,
                response_horario_data: action.payload.response,
                message_horario_data: action.payload.message
            });
        case REQUEST_HORARIO_FAILED:
            return Object.assign({}, state, {
                horario_data_isPending: false,
                horario_data_isSuccess: action.payload.success,
                error_horario_data: action.payload.message
            });
        case UPDATE_HORARIO_PENDING:
            return Object.assign({}, state, { horario_data_isPending: true });
        case UPDATE_HORARIO_SUCCESS:
            return Object.assign({}, state, {
                horario_data_isPending: false,
                horario_data: action.payload,
                horario_data_isSuccess: action.payload.success,
                response_horario_data: action.payload.response,
                message_horario_data: action.payload.message
            });
        case UPDATE_HORARIO_FAILED:
            return Object.assign({}, state, {
                horario_data_isPending: false,
                horario_data_isSuccess: action.payload.success,
                error_horario_data: action.payload.message
            });
        default:
            return state;
    }
}

const initialNotaState = {
    nota_data_isPending: true,
    nota_data: [],
    nota_data_isSuccess: false,
    response_nota_data: [],
    message_nota_data: "",
    error_nota_data: ""
}

export const Nota_Ferias = (state = initialNotaState, action = {}) => {
    switch (action.type) {
        case REQUEST_NOTA_FERIAS_PENDING:
            return Object.assign({}, state, { nota_data_isPending: true });
        case REQUEST_NOTA_FERIAS_SUCCESS:
            return Object.assign({}, state, {
                nota_data_isPending: false,
                nota_data: action.payload,
                nota_data_isSuccess: action.payload.success,
                response_nota_data: action.payload.response,
                message_nota_data: action.payload.message
            });
        case REQUEST_NOTA_FERIAS_FAILED:
            return Object.assign({}, state, {
                nota_data_isPending: false,
                nota_data_isSuccess: action.payload.success,
                error_nota_data: action.payload.message
            });
        default:
            return state;
    }
}

const initialContactsState = {
    contacts_data_isPending: true,
    contacts_data: [],
    contacts_data_isSuccess: false,
    response_contacts_data: [],
    message_contacts_data: "",
    error_contacts_data: "",
    metodos_data_isPending: true,
    metodos_data: [],
    metodos_data_isSuccess: false,
    response_metodos_data: [],
    message_metodos_data: "",
    error_metodos_data: "",
    redes_data_isPending: true,
    redes_data: [],
    redes_data_isSuccess: false,
    response_redes_data: [],
    message_redes_data: "",
    error_redes_data: "",

    details_data_isPending: true,
    details_data: [],
    details_data_isSuccess: false,
    response_details_data: [],
    message_details_data: "",
    error_details_data: ""
}

export const Details = (state = initialContactsState, action = {}) => {
    switch (action.type) {
        case REQUEST_CONTACTS_PENDING:
            return Object.assign({}, state, { contacts_data_isPending: true });
        case REQUEST_CONTACTS_SUCCESS:
            return Object.assign({}, state, {
                contacts_data_isPending: false,
                contacts_data: action.payload,
                contacts_data_isSuccess: action.payload.success,
                response_contacts_data: action.payload.response,
                message_contacts_data: action.payload.message
            });
        case REQUEST_CONTACTS_FAILED:
            return Object.assign({}, state, {
                contacts_data_isPending: false,
                contacts_data_isSuccess: action.payload.success,
                error_contacts_data: action.payload.message
            });
        case REQUEST_METODOS_PENDING:
            return Object.assign({}, state, { metodos_data_isPending: true });
        case REQUEST_METODOS_SUCCESS:
            return Object.assign({}, state, {
                metodos_data_isPending: false,
                metodos_data: action.payload,
                metodos_data_isSuccess: action.payload.success,
                response_metodos_data: action.payload.response,
                message_metodos_data: action.payload.message
            });
        case REQUEST_METODOS_FAILED:
            return Object.assign({}, state, {
                metodos_data_isPending: false,
                metodos_data_isSuccess: action.payload.success,
                error_metodos_data: action.payload.message
            });
        case REQUEST_REDES_PENDING:
            return Object.assign({}, state, { redes_data_isPending: true });
        case REQUEST_REDES_SUCCESS:
            return Object.assign({}, state, {
                redes_data_isPending: false,
                redes_data: action.payload,
                redes_data_isSuccess: action.payload.success,
                response_redes_data: action.payload.response,
                message_redes_data: action.payload.message
            });
        case REQUEST_REDES_FAILED:
            return Object.assign({}, state, {
                redes_data_isPending: false,
                redes_data_isSuccess: action.payload.success,
                error_redes_data: action.payload.message
            });
        case UPDATE_DETAILS_PENDING:
            return Object.assign({}, state, { details_data_isPending: true });
        case UPDATE_DETAILS_SUCCESS:
            return Object.assign({}, state, {
                details_data_isPending: false,
                details_data: action.payload,
                /* response_contacts_data: action.payload.response, */ // change this to include the changes when changing logo
                details_data_isSuccess: action.payload.success,
                response_details_data: action.payload.response,
                message_details_data: action.payload.message
            });
        case UPDATE_DETAILS_FAILED:
            return Object.assign({}, state, {
                details_data_isPending: false,
                details_data_isSuccess: action.payload.success,
                error_details_data: action.payload.message
            });
        default:
            return state;
    }
}


const initialEventsState = {
    events_details_data_isPending: true,
    events_details_data: [],
    events_details_data_isSuccess: false,
    response_events_details_data: [],
    message_events_details_data: "",
    error_events_details_data: "",
    event_photo_data_isPending: true,
    event_photo_data: [],
    event_photo_data_isSuccess: false,
    response_event_photo_data: [],
    message_event_photo_data: "",
    error_event_photo_data: ""
}

export const Events = (state = initialEventsState, action = {}) => {
    switch (action.type) {
        case REQUEST_EVENTS_PENDING:
            return Object.assign({}, state, { events_details_data_isPending: true });
        case REQUEST_EVENTS_SUCCESS:
            return Object.assign({}, state, {
                events_details_data_isPending: false,
                events_details_data: action.payload,
                events_details_data_isSuccess: action.payload.success,
                response_events_details_data: action.payload.response,
                message_events_details_data: action.payload.message
            });
        case REQUEST_EVENTS_FAILED:
            return Object.assign({}, state, {
                events_details_data_isPending: false,
                events_details_data_isSuccess: action.payload.success,
                error_events_details_data: action.payload.message
            });
        case UPDATE_EVENTS_PENDING:
            return Object.assign({}, state, { events_details_data_isPending: true });
        case UPDATE_EVENTS_SUCCESS:
            return Object.assign({}, state, {
                events_details_data_isPending: false,
                events_details_data: action.payload,
                events_details_data_isSuccess: action.payload.success,
                response_events_details_data: action.payload.response,
                message_events_details_data: action.payload.message
            });
        case UPDATE_EVENTS_FAILED:
            return Object.assign({}, state, {
                events_details_data_isPending: false,
                events_details_data_isSuccess: action.payload.success,
                error_events_details_data: action.payload.message
            });
        case REQUEST_EVENTDETAILS_PENDING:
            return Object.assign({}, state, { events_details_data_isPending: true });
        case REQUEST_EVENTDETAILS_SUCCESS:
            return Object.assign({}, state, {
                events_details_data_isPending: false,
                events_details_data: action.payload,
                events_details_data_isSuccess: action.payload.success,
                response_events_details_data: action.payload.response,
                message_events_details_data: action.payload.message
            });
        case REQUEST_EVENTDETAILS_FAILED:
            return Object.assign({}, state, {
                events_details_data_isPending: false,
                events_details_data_isSuccess: action.payload.success,
                error_events_details_data: action.payload.message
            });
        case REQUEST_EVENTPHOTOS_PENDING:
            return Object.assign({}, state, { event_photo_data_isPending: true });
        case REQUEST_EVENTPHOTOS_SUCCESS:
            return Object.assign({}, state, {
                event_photo_data_isPending: false,
                event_photo_data: action.payload,
                event_photo_data_isSuccess: action.payload.success,
                response_event_photo_data: action.payload.response,
                message_event_photo_data: action.payload.message
            });
        case REQUEST_EVENTPHOTOS_FAILED:
            return Object.assign({}, state, {
                event_photo_data_isPending: false,
                event_photo_data_isSuccess: action.payload.success,
                error_event_photo_data: action.payload.message
            });
        case UPDATE_EVENTPHOTOS_PENDING:
            return Object.assign({}, state, { event_photo_data_isPending: true });
        case UPDATE_EVENTPHOTOS_SUCCESS:
            return Object.assign({}, state, {
                event_photo_data_isPending: false,
                event_photo_data: action.payload,
                event_photo_data_isSuccess: action.payload.success,
                response_event_photo_data: action.payload.response,
                message_event_photo_data: action.payload.message
            });
        case UPDATE_EVENTPHOTOS_FAILED:
            return Object.assign({}, state, {
                event_photo_data_isPending: false,
                event_photo_data_isSuccess: action.payload.success,
                error_event_photo_data: action.payload.message
            });
        case DELETE_PHOTO_PENDING:
            return Object.assign({}, state, { event_photo_data_isPending: true });
        case DELETE_PHOTO_SUCCESS:
            return Object.assign({}, state, {
                event_photo_data_isPending: false,
                event_photo_data: action.payload,
                event_photo_data_isSuccess: action.payload.success,
                response_event_photo_data: action.payload.response,
                message_event_photo_data: action.payload.message
            });
        case DELETE_PHOTO_FAILED:
            return Object.assign({}, state, {
                event_photo_data_isPending: false,
                event_photo_data_isSuccess: action.payload.success,
                error_event_photo_data: action.payload.message
            });
        default:
            return state;
    }
}

const initialUserState = {
    user_data_isPending: true,
    user_data: [],
    user_data_isSuccess: false,
    response_user_data: [],
    message_user_data: "",
    error_user_data: ""
}

export const User = (state = initialUserState, action = {}) => {
    switch (action.type) {
        case UPDATE_USERDATA_PENDING:
            return Object.assign({}, state, { user_data_isPending: true});
        case UPDATE_USERDATA_SUCCESS:
            return Object.assign({}, state, {
                user_data_isPending: false,
                user_data: action.payload,
                user_data_isSuccess: action.payload.success,
                response_user_data: action.payload.response,
                message_user_data: action.payload.message
            });
        case UPDATE_USERDATA_FAILED:
            return Object.assign({}, state, {
                user_data_isPending: false,
                user_data_isSuccess: action.payload.success,
                error_user_data: action.payload.message
            });
        default:
            return state;
    }
}

const initialLikesState = {
    like_data_isPending: true,
    like_data: [],
    like_data_isSuccess: false,
    response_like_data: [],
    message_like_data: "",
    error_like_data: ""
}


export const Likes = (state = initialLikesState, action = {}) => {
    switch (action.type) {
        case UPDATE_LIKES_PENDING:
            return Object.assign({}, state, { like_data_isPending: true});
        case UPDATE_LIKES_SUCCESS:
            return Object.assign({}, state, {
                like_data_isPending: false,
                like_data: action.payload,
                like_data_isSuccess: action.payload.success,
                response_like_data: action.payload.response,
                message_like_data: action.payload.message
            });
        case UPDATE_LIKES_FAILED:
            return Object.assign({}, state, {
                like_data_isPending: false,
                like_data_isSuccess: action.payload.success,
                error_like_data: action.payload.message
            });
        default:
            return state;
    }
}


const initialClientListState = {
    client_list_data_isPending: true,
    client_list_data: [],
    client_list_data_isSuccess: false,
    response_client_list_data: [],
    message_client_list_data: "",
    error_client_list_data: ""
}


export const ClientList = (state = initialClientListState, action = {}) => {
    switch (action.type) {
        case UPDATE_CLIENTLIST_PENDING:
            return Object.assign({}, state, { client_list_data_isPending: true});
        case UPDATE_CLIENTLIST_SUCCESS:
            return Object.assign({}, state, {
                client_list_data_isPending: false,
                client_list_data: action.payload,
                client_list_data_isSuccess: action.payload.success,
                response_client_list_data: action.payload.response,
                message_client_list_data: action.payload.message
            });
        case UPDATE_CLIENTLIST_FAILED:
            return Object.assign({}, state, {
                client_list_data_isPending: false,
                client_list_data_isSuccess: action.payload.success,
                error_client_list_data: action.payload.message
            });
        case DELETE_CLIENT_PENDING:
            return Object.assign({}, state, { client_list_data_isPending: true});
        case DELETE_CLIENT_SUCCESS:
            return Object.assign({}, state, {
                client_list_data_isPending: false,
                client_list_data: action.payload,
                client_list_data_isSuccess: action.payload.success,
                response_client_list_data: action.payload.response,
                message_client_list_data: action.payload.message
            });
        case DELETE_CLIENT_FAILED:
            return Object.assign({}, state, {
                client_list_data_isPending: false,
                client_list_data_isSuccess: action.payload.success,
                error_client_list_data: action.payload.message
            });
        default:
            return state;
    }
}
